// exportParameters for metaperson_2.0 (.webp)

const avatarLod = process.env.USE_LOD2 === '0' ? 'LOD1' : 'LOD2'
const textureSet = process.env.USE_LOD2 === '0' ? '2K.webp' : '1K.webp'

export const avatarParameters = () => {
  return {
    avatar_modifications: {
      remove_smile: true,
      remove_glasses: true,
    },
  }
}

export const exportParameters = (type: 'male' | 'female') => {
  return [
    //
    {
      format: 'gltf',
      lod: avatarLod,
      textures: {
        profile: textureSet,
      },

      avatar: {
        list:
          process.env.USE_ARTIFICIALEYES === '1'
            ? [
              'AvatarBody',
              'AvatarEyelashes',
              'AvatarHead',
              'AvatarLeftCornea',
              'AvatarLeftEyeball',
              'AvatarRightCornea',
              'AvatarRightEyeball',
              'AvatarTeethLower',
              'AvatarTeethUpper',
            ]
            : [
              'AvatarBody',
              'AvatarEyelashes',
              'AvatarHead',
              'AvatarLeftCornea',
              'AvatarRightCornea',
              'AvatarTeethLower',
              'AvatarTeethUpper',
            ],
        textures: {
          list: ['Color'],
        },
      },

      haircuts: {
        list:
          process.env.USE_NEWHAIRCUTS === '1'
            ? [
              'HaircutGenerated',
              'Haircut0',
              'Haircut1',
              'Haircut2',
              'Haircut3',
              'Haircut4',
              'Haircut5',
              'Haircut6',
              'Haircut7',
              'Haircut8',
              'Haircut9',
              'Haircut10',
              'Haircut11',
              'Haircut12',
              'Haircut13',
            ]
            : ['Haircut0', 'Haircut1', 'Haircut2', 'Haircut3', 'Haircut4', 'Haircut5', 'Haircut6', 'HaircutGenerated'],
        embed: false,
        textures: {
          embed: false,
          list: [
            'AO',
            'Alpha',
            'Color',
            'Depth',
            //'GltfMetallicRoughness',
            //'Normal',
            'Root',
            //'Roughness',
            'Scalp',
            //'ScalpShade',
            //'ScalpShadeAOAlpha',
            //'ScalpShadeAlpha',
            'Shade',
            'UniqueID',
            //'UnityMetallicSmoothness'
          ],
        },
      },

      ...(process.env.USE_GLASSES === '1' && {
        glasses: {
          embed: false,
          list: [
            'glasses_00',
            'glasses_01',
            'glasses_02',
            'glasses_03',
            'glasses_04',
            'glasses_05',
            'glasses_06',
            'glasses_07',
            'glasses_08',
            'glasses_09',
            'glasses_10',
            'glasses_11',
            'glasses_12',
            'glasses_13',
            'glasses_14',
            'glasses_15',
          ],
          textures: {
            embed: false,
            list: [
              'Color',
              'GltfMetallicRoughness',
              'Metallic',
              'Roughness',
              //'UnityMetallicSmoothness'
            ],
          },
        },
      }),

      blendshapes: {
        list: ['face_modifications', 'body_shape', 'emotions', 'mobile_51'],
        embed: true,
      },
    },
    //
  ]
}
