import { BlobReader, ZipReader, BlobWriter, Entry } from '@zip.js/zip.js'
import { loadAsync } from './jszip-3.7.1.min.js'

export async function proceedZip(zip) {
  //  console.log('proceedZip():', zip)

  const res = await loadAsync(zip).then((z) => {
    let promises = []

    for (const item of Object.values(z.files)) {
      if (item.dir) continue

      let filename = item.name.split('/')
      filename = filename[filename.length - 1]
      let p = item.async('blob').then((blob) => {
        let url = {}
        url[filename] = URL.createObjectURL(blob)
        return url
      })

      promises.push(p)
    }

    return Promise.all(promises).then((blobs) => {
      return blobs.reduce((a, i) => {
        Object.assign(a, i)
        return a
      }, {})
    })
  })

  return res
}

export async function proceedZipOld(zip) {
  console.log('proceedZip(Old):', zip)

  // unpack model.zip

  const reader = new ZipReader(new BlobReader(zip))
  const entries = await reader.getEntries()
  console.log('entries:', entries)

  /*
  let blobs = await Promise.all(
    entries.map(async (element) => {
      if (!element.directory) {
        console.log('element:', element)

        const controller = new AbortController()
        const signal = controller.signal

        const blob = await element.getData(new BlobWriter(), { signal })
        console.log('blob:', blob)

        const url = URL.createObjectURL(blob)

        return { [element.filename.replace(/^.*(\\|\/|:)/, '')]: url }
      }
      return null
    }),
  )
  console.log('blobs:', blobs)
*/

  if (entries.length === 11) {
    const testBlob = entries[2].getData(new BlobWriter(), {
      onprogress: (index, max) => {
        console.log(index, max)
      },
    })
    console.log('testBlob:', testBlob)
  }

  const tmp = entries.map(async (element) => {
    if (!element.directory) {
      const controller = new AbortController()
      const signal = controller.signal

      const blob = await element.getData(new BlobWriter(), { signal })
      const url = URL.createObjectURL(blob)

      return { [element.filename.replace(/^.*(\\|\/|:)/, '')]: url }
    }
    return null
  })
  console.log('tmp:', tmp)

  /*
  const tmp2 = []
  for (let i=1; i<entries.length; ++i){
    tmp2[i-1] = entries[i].getData( new BlobWriter() )
  }
  console.log("tmp2:", tmp2)
*/

  let blobs = await Promise.all(tmp)

  blobs = blobs
    .filter((blob) => !!blob)
    .reduce(function (result, item) {
      var key = Object.keys(item)[0]
      result[key] = item[key]
      return result
    }, {})

  await reader.close()

  console.log('blobs:', blobs)

  return blobs
}
