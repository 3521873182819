import React, { FC, useState, useRef, useEffect, useCallback } from 'react'
import styled from 'astroturf/react'

import { AvatarViewer, defaultParams } from '@/avatarViewer/index.js'
import { SVGIcon, ApiKeysUnion } from '@/helpers/types'
import { Loader } from '@/components/ui'
import { storeGeneratorPipeline } from '@/services/store/generatorPipeline'
import { cameraPositions } from '../cameraPositions'

import { storeEditor } from '@/services/store'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/ui'

export type Components = 'Color' | 'Face' | 'Body' | 'Hairs' | 'Glasses' | 'Shirt'

export interface Acc {
  apiKey: ApiKeysUnion
  icon: SVGIcon
  component: FC
}

export interface ViewerWrapProps {
  variant: 'not-mobile' | 'mobile'
}

export const ViewerWrap: FC<ViewerWrapProps> = observer(({ variant }) => {
  //console.log("!!! ViewerWrap()")

  const viewerRef = useRef(null)

  const {
    setCamReady,
    setModelReady,
    setHaircutReady,
    setGlassesReady,
    setOutfitReady,
    isCamReady,
    isModelReady,
    isHaircutReady,
    isGlassesReady,
    isOutfitReady,
    setViewer,
  } = storeEditor

  const { avatar } = storeGeneratorPipeline
  //  const { haircut, outfits, params } = storeEditor
  const { haircut, outfits, glasses } = storeEditor

  const [animation, setAnimation] = useState({ name: 'avatars/female_animated0/model.gltf', started: false })

  const [params, setParams] = useState(() => {
    const params = {
      ...defaultParams,
      camera: { ...defaultParams.camera, ...cameraPositions('BODY', variant === 'not-mobile') },
    }
    //    console.log("params:", params)
    return params
  })

  useEffect(() => {
    if (viewerRef?.current) {
      setViewer(viewerRef.current)
    }
  }, [viewerRef?.current])

  //  useEffect(() => {
  //console.log("!!!!!!!!!! ViewerWrap useEffect()")
  //  }, [])

  const onDownloadResource = useCallback(async (type: string, name: string) => {
    //    console.log('onDownloadResource():', type, name)

    try {
      //console.log('/' + type + 's/' + name)

      if (type === 'haircut') {
        return storeGeneratorPipeline.getHaircut(name)
      }

      if (type === 'glasses') {
        return storeGeneratorPipeline.getGlasses(name)
      }

      const res = await fetch('/' + type + 's/' + name)
        .then((r) => r.blob())
        .catch((error) => {
          'ERROR: fetch()', error
        })

      return res
    } catch (error) {
      //console.log('ERROR: onDownloadResource(), error:', error)
    }
    return null
  }, [])

  const setReady = (item, isReady, data = null) => {
    //console.log("setReady():", item, isReady, data)

    switch (item) {
      case 'camera':
        setCamReady(isReady)
        break
      case 'avatar':
        storeGeneratorPipeline.avatar.skinColor = storeGeneratorPipeline.avatar.skinColorInitial = data
          ? data.skinColorInitial
          : null
        setModelReady(isReady)
        break
      case 'haircut':
        setHaircutReady(isReady)
        break
      case 'glasses':
        setGlassesReady(isReady)
        break
      case 'outfit':
        setOutfitReady(isReady)
        break
    }
  }

  //console.log("!!! ViewerWrapProps render")
  //console.log("  avatar=",  avatar)
  //console.log("  outfits=", outfits)
  //console.log("  glasses=", glasses)
  //console.log("  iscamReady:", isCamReady)
  //  console.log("  isOutfitReady:", isOutfitReady)

  //const toggleAnimation = () => {
  //  console.log("toggleAnimation()")
  //  setAnimation()
  //}

  //<Button onClick={toggleAnimation}> Animation  </Button>

  //      <ExtendedViewer isHidden={!isCamReady || !isOutfitReady}>

  //console.log("isCamReady:", isCamReady, "isModelReady:", isModelReady, "isHaircutReady:", isHaircutReady, "isGlassesReady:", isGlassesReady, "isOutfitReady2:", isOutfitReady)

  const isReady = isCamReady && isModelReady && isHaircutReady && isGlassesReady && isOutfitReady

  return (
    <Core variant={variant}>
      <AvatarViewer
        ref={viewerRef}
        avatar={avatar}
        haircut={haircut}
        glasses={glasses}
        outfits={outfits}
        animation={animation}
        params={params}
        onDownloadResource={onDownloadResource}
        setReady={setReady}
      />
      {!isReady && <Loader spinner='circle' variant='overlay' />}
    </Core>
  )
})

export interface Variant {
  variant: 'not-mobile' | 'mobile'
}

const Core = styled.div<Variant>`
  width: 100%;
  &.variant-mobile {
    height: var(--app-height);
  }

  &.variant-not-mobile {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
`

export interface ExtendedViewer {
  isHidden: boolean
}

const ExtendedViewer = styled.div<ExtendedViewer>`
  @import '../../../../../../assets/styles/mixins.scss';
  @include anim(opacity);
  height: 100%;

  &.isHidden {
    opacity: 0;
  }
`
