import React from 'react'

import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { proceedZip } from '../helpers/prepareModel'

class Glasses {
  ref = null

  subtype = null
  name = null

  nodes = null
  materials = null

  busy = false

  /**
   * Clear model
   */

  clearModel = () => {
    console.log('clearModel()')

    if (this.nodes && this.nodes[this.name]) this.nodes[this.name].geometry.dispose()

    if (this.materials && this.materials[this.name]) this.materials[this.name].dispose()

    this.nodes = null
    this.materials = null

    this.subtype = null
    this.name = null
  }

  /**
   * Prepare Glasses
   *
   * param glasses            - object with glasses name
   * param onDownloadResource - callback to load .zip and absend in .zip textures
   */

  prepareGlasses = async (glasses, onDownloadResource) => {
    console.log('prepareGlasses()')
    console.log('  glasses.name:', glasses.name)
    console.log('  this.name:', this.name)

    //console.log('  performance.memory.usedJSHeapSize, MB:', performance.memory.usedJSHeapSize / 1048576)

    if (glasses.name === null) {
      this.clearModel()
      return
    }

    this.busy = true

    const zip = await onDownloadResource('glasses', glasses.name)
    //console.log('zip:', zip)

    const blobs = await proceedZip(zip)
    //console.log('blobs:', blobs)

    const model = await fetch(blobs['model.gltf'])
      .then((res) => res.text())
      .then((data) => JSON.parse(data))

    if (blobs['model.bin']) model.buffers[0].uri = blobs['model.bin']
    if (blobs['animations.bin']) model.buffers[1].uri = blobs['animations.bin']

    //console.log('model.images:', model.images)

    const textureLoader = new THREE.TextureLoader()

    for (let i = 0; i < model.images?.length; ++i) {
      //console.log('model.images:', i, model.images[i])

      const uri = model.images[i].uri
      model.images[i].uri = blobs[model.images[i].uri]
        ? blobs[model.images[i].uri]
        : URL.createObjectURL(await onDownloadResource('texture', model.images[i].uri))
    }
    //console.log('model:', model)

    const url = URL.createObjectURL(new Blob([JSON.stringify(model, null, 2)], { type: 'text/plain' }))
    //console.log('url:', url)

    var gltfLoader = new GLTFLoader()
    const gltf = await gltfLoader.loadAsync(url)

    const nodes = await gltf.parser.getDependencies('node')
    const materials = await gltf.parser.getDependencies('material')

    console.log('data loaded')

    this.clearModel()

    this.nodes = {}
    nodes.forEach((item) => {
      this.nodes[item.name] = item
    })

    this.materials = {}
    materials.forEach((material) => {
      material.side = THREE.DoubleSide
      material.depthWrite = true
      this.materials[material.name] = material
    })

    this.subtype = glasses.subtype
    this.name = glasses.name

    this.busy = false
  }

  /**
   * Glasses jsx
   *
   * param ref          - react.js useRef()
   */

  jsxGlasses = (ref, params) => {
    console.log('jsxGlasses()')
    console.log('  name:', this.name)
    //console.log('  this.nodes:', this.nodes)
    //console.log('  this.materials:', this.materials)

    if (!this.nodes || !this.name || !this.nodes[this.name] || !this.nodes[this.name].geometry) return null

    this.ref = ref

    //console.log('render')

    return (
      <group {...params} dispose={null} ref={this.ref}>
        <primitive object={this.nodes.Hips} />

        <skinnedMesh
          name={this.name}
          geometry={this.nodes[this.name].geometry}
          material={this.materials[this.name]}
          skeleton={this.nodes[this.name].skeleton}
        />
      </group>
    )
  }
}

const storeGlasses = new Glasses()

export { storeGlasses }
