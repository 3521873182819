export const avatarHead_fragment1 = `
  precision mediump float;

  uniform sampler2D uHead;
  uniform sampler2D uHaircut;
  uniform sampler2D uScalp;

  uniform bool  uUseSkinRecoloring;
  uniform mat3  uR;

  uniform bool  uUseGeneratedHaircut;
  uniform bool  uUseGeneratedHaircutRecoloring;
  uniform vec3  uColorTint;
  uniform float uTintCoeff;
  uniform float uDarkeningCoeff;

  uniform bool  uUseScalpRecoloring;
  uniform vec3  uScalpColor;
`
