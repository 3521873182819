import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { observer } from 'mobx-react-lite'
import styled from 'astroturf/react'
import { useRQ, getSetting, createPlayerID, request, getQRFile } from '@/services/adapters/req'
import { Typography, Loader, Button, Logo } from '@/components/ui'
import { Profile, Home, ArrowLeft } from '@/components/ui/icons'
import { Modal } from '@/components/wrapper'
import { Container } from '@/components/wrapper'
import { SECTIONS, Setting, Section } from './_helper'
import { storeAuth, storeGeneratorPipeline, storeEditor } from '@/services/store'
import { redirect, useNavigate } from "react-router-dom";
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

export interface GeneratorProps {
  clientID: string
  clientSecret: string
}

export const ProgressBar = ({ value, max }) => {
  const [progress, setProgress] = useState(0);


  useEffect(() => {
    setProgress((value / max) * 100);
  }, [value, max]);

  return (
    <ProgressContainer>
      <ProgressDiv
        style={{ width: `${progress}%` }}
      ></ProgressDiv>
    </ProgressContainer>
  );
};

export const Generator: FC<GeneratorProps> = observer(({ clientID, clientSecret }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingFake, setIsLoadingFake] = useState(true)
  const {setIsOpenModal} = useLayoutContext()
  const [userData, setUserData] = useState('');
  const {isAuthorization, isLoading: isLoadingAuth } = storeAuth
  const {
    sectionIndex,
    setAvailableSections,
    setSectionIndex,
    isSendingPreSetting,
    toSection,
    startStatePhoto,
    progress,
    cleanPipeline,
    avatarId,
    getExportsFile,
    exportingAvatar,
    getBusinessToken
  } = storeGeneratorPipeline
  const { cleanViewerState } = storeEditor
  const {
    isLoading: isLoadingSetting,
    data: {
      availableSections,
      texts: { sections, general },
    },
  } = useRQ<Setting>(getSetting)
  const { isLoading, data } = useRQ(createPlayerID, { comment: 'curl sample' })

  const [externalAuthenticateData, setExternalAuthenticateData] = useState({})
  const [externalExportData, setExternalExportData] = useState({})
  const getExternalData = useCallback((e) => {
    if (e?.data?.eventName === 'authenticate') {
      setExternalAuthenticateData(e.data)
    }
    else if (e?.data?.eventName === 'set_export_parameters') {
      setExternalExportData(e.data)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', getExternalData)
    return () => window.removeEventListener('message', getExternalData)
  }, [])

  setAvailableSections(availableSections)
  const navigateTo = () => {
    setSectionIndex(0)
    navigate('/generator')
  }

  useEffect(() => {
      window.parent.postMessage(
        {
          eventName: 'mobile_loaded',
          source: 'metaperson_creator'
        }, '*');
      }, []);

  useEffect(() => {
    if (externalAuthenticateData.clientId != undefined || externalAuthenticateData.clientSecret != undefined)
    {
      getBusinessToken(externalAuthenticateData.clientId, externalAuthenticateData.clientSecret)
    }
    else
    {
      storeGeneratorPipeline.businessToken = ""
      storeGeneratorPipeline.businessMode = ""
    }
  }, [externalAuthenticateData])
  
  useEffect(() => {
    if (clientID && clientSecret) {
      storeAuth.send({
        client_id: clientID,
        client_secret: clientSecret,
      })
    }
    setTimeout(() => {
      setIsLoadingFake(false)
    }, 0)
  }, [clientID, clientSecret])

  const {
    component: Component,
    settings,
    componentID,
  } = useMemo(() => {
    if (isLoadingSetting) {
      return {}
    }
    return SECTIONS[availableSections[sectionIndex]]
  }, [availableSections, isLoadingSetting, sectionIndex]) as Section

  const prevPosition = useCallback(() => {
    if (availableSections[sectionIndex] === 'photo' && startStatePhoto) {
      startStatePhoto()
      return
    }
    setSectionIndex(sectionIndex - 1)
  }, [availableSections, sectionIndex, startStatePhoto])

  useEffect(() => {
    const storedData = localStorage.getItem("VerifiedUserObj");
    if (storedData) {
      setUserData(storedData);
    }
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen)
  };
  const closeIt = () => {
    setIsOpen(false)
  }
 const redirectToProfile = () => {
  navigate('/auth/profile')
 }
  const cleanState = useCallback(() => {
    navigate('/auth')
    setSectionIndex(0)
    cleanPipeline()
    cleanViewerState()
  }, [])

  const handleExportClick = async () => {
    setIsOpenModal(true);
    if (avatarId === undefined) {
      process.env.USE_MIRROR === '0' ? exportingAvatar("1ce4c31b-fd2d-4416-a2d1-6d8262bce1b5", setIsOpenModal, externalExportData) : exportingAvatar("c8a374f3-9964-43ec-b11d-9e021625cf17", setIsOpenModal, externalExportData)
    }
    else {
      exportingAvatar(avatarId, setIsOpenModal, externalExportData);
    }
  }

  if (isLoadingFake || isLoadingSetting || (isLoadingAuth && isAuthorization)) {
    return (
      <Start>
        <Helmet>
          <title>Avatar Generator</title>
        </Helmet>
        <Logo width={80} height={64} />
        <NameCompany variant='h3'>{general.company_name}</NameCompany>
        <Loader spinner='circle' variant='content' />
        <div className="profile-icon">
    {/* <img src="https://example.com/profile-picture.png" alt="Profile Picture" /> */}
  </div>
      </Start>
    )
  }

  if (isSendingPreSetting) {
    return (
      <Container variant='center'>
        <Loader spinner='infinite' variant='content' />
        <LoadingMSG>{general.waiting_for_generation}</LoadingMSG>
        <Progress>
          {general.progress}{progress}{general.progress2}
        </Progress>
      </Container>
    )
  }
  return (
    <>
      <Helmet>
        <title>{sections[componentID].page_title}</title>
      </Helmet>
      <Core>
        <Header>
          {settings.withStartNav && (
            <>
            {userData ? (
            <> 
            {sectionIndex === 0 ? (
            <Button isOnlyIcon variant='surface' onClick={redirectToProfile}>
            <Profile />
            </Button>) : (
              <Button isOnlyIcon variant='surface' onClick={navigateTo}>
              <Home />
              </Button>
            )}
            </>
            ) : (
            <>
            {sectionIndex === 0 ? (
            <Button isOnlyIcon variant='surface' onClick={cleanState}>
            <Profile />
            </Button>) : (
              <Button isOnlyIcon variant='surface' onClick={navigateTo}>
              <Home />
              </Button>
            )}
            </>
            )}
            </>
          )}
          <div>          
          {settings.withSaveAvatar && storeGeneratorPipeline.businessMode !== "error" && <Button onClick={handleExportClick}>{general.save}</Button>}


          {sectionIndex !== 0 && settings.withPrevNav && (
            <>
            <Button isOnlyIcon variant='surface' onClick={prevPosition}>
              <ArrowLeft />
            </Button>
            </>
          )}
          </div>
        </Header>
        {Component && <Component />}
      </Core>
      <Modal>
        <Progress>Exporting the avatar ...</Progress>
        <ProgressBar value={progress} max={100} />
      </Modal>
    </>
  )
})

const Start = styled.div`
  min-height: var(--app-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 32vh;
  padding-bottom: 120px;

  .profile-icon {
    width: 64px;
    height: 64px;
    margin-top: 22px;
    margin-bottom: auto;
  }

  @media (prefers-color-scheme: dark) {
    .profile-icon {
      background-color: black;
    }
  }
`

const NameCompany = styled(Typography)`
  margin-top: 22px;
  margin-bottom: auto;
`

const Progress = styled(Typography)`
  text-align: center;
  margin-bottom: 12px;
`

const ProgressContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
`
const ProgressDiv = styled.div`
  height: 100%;
  background-image: linear-gradient(102.67deg, #7d1bf5 0%, #e32dd1 99.48%);
  transition: width 0.3s ease-in-out;
`

const LoadingMSG = styled(Typography)`
  margin-top: 4px;
  text-align: center;
  max-width: 240px;
`

const Core = styled.div`
  min-height: var(--app-height);
`
const CoreOne = styled.div`
position: fixed;
top: 0;
right: 0;

`


const Header = styled.div`
  display: flex;
  width: calc(100% - 64px);
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 32px;
  z-index: 5;
`
