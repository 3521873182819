import React from 'react'
import { createRoot } from 'react-dom/client'
import { Web } from './views/components'
import { setConfiguration } from 'react-grid-system'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import '@/assets/styles/theme.scss'
import '@/assets/styles/main.scss'

setConfiguration({ gutterWidth: 24 })
const isLightTheme = window.matchMedia('(prefers-color-scheme: light)').matches
document.body.classList.add(isLightTheme ? 'light' : 'dark')
const container = document.getElementById('app') as HTMLElement
const root = createRoot(container)

const appHeight = () => {
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
}
appHeight()

const supportsOrientationChange = 'onorientationchange' in window,
  orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize'

window.addEventListener(
  orientationEvent,
  function () {
    setTimeout(() => {
      appHeight()
    }, 100)
  },
  false,
)

root.render(
  <>
    <Web />
    <ToastContainer />
  </>,
)
