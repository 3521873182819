import React from 'react'

import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { proceedZip } from '../helpers/prepareModel'

import { haircut_fragment1 } from '../shaders/haircut_fragment1.glsl'
import { haircut_fragment2 } from '../shaders/haircut_fragment2.glsl'

class Haircut {
  ref = null

  color = null
  subtype = null
  name = null

  nodes = null
  materials = null
  textures = null

  busy = false

  uniforms = {}

  /**
   * Constructor
   */

  constructor() {
    this.uniforms.uAO = { value: null }
    this.uniforms.uAlpha = { value: null }
    this.uniforms.uColor = { value: null }
    this.uniforms.uDepth = { value: null }
    this.uniforms.uRoot = { value: null }
    this.uniforms.uShade = { value: null }
    this.uniforms.uUniqueID = { value: null }

    this.uniforms.uRecoloring = { value: false }
    this.uniforms.uTargetColor = { value: new THREE.Vector4(0, 0, 0, 0) }
    this.uniforms.uRootsColor = { value: new THREE.Vector4(0, 0, 0, 0) }

    this.uniforms.uAOImpact = { value: 0.75 }
    this.uniforms.uDepthImpact = { value: 1.0 }
    this.uniforms.uIDsImpact = { value: 1.0 }
  }

  /**
   * Clear model
   */

  clearModel = () => {
    console.log('clearModel()')

    if (this.nodes && this.nodes[this.name]) this.nodes[this.name].geometry.dispose()

    if (this.materials && this.materials[this.name]) this.materials[this.name].dispose()

    this.nodes = null
    this.materials = null
    //this.textures = null

    this.subtype = null
    this.name = null
    //    this.color = null
  }

  /**
   * Prepare Haircut
   *
   * param haircut            - object with haircut name
   * param onDownloadResource - callback to load .zip and absend in .zip textures
   */

  prepareHaircut = async (haircut, onDownloadResource) => {
    console.log('prepareHaircut()')
    console.log('  haircut:', haircut)
    //    console.log('  this:', this)

    //console.log('  performance.memory.usedJSHeapSize, MB:', performance.memory.usedJSHeapSize / 1048576)

    if (haircut.preset === 'Bald') {
      this.clearModel()
      this.subtype = haircut.subtype
      this.name = haircut.preset
      this.color = null
      return
    }

    this.busy = true
    this.color = null

    //    const zip = await onDownloadResource('haircut', haircut.subtype + '/' + haircut.preset + '.zip')
    const zip = await onDownloadResource('haircut', haircut.preset)
    //console.log('zip:', zip)

    const blobs = await proceedZip(zip)
    //console.log('blobs:', blobs)

    const model = await fetch(blobs['model.gltf'])
      .then((res) => res.text())
      .then((data) => JSON.parse(data))

    if (blobs['model.bin']) model.buffers[0].uri = blobs['model.bin']
    if (blobs['animations.bin']) model.buffers[1].uri = blobs['animations.bin']

    //console.log('model.images:', model.images)

    this.textures = {}
    const textureLoader = new THREE.TextureLoader()

    for (let i = 0; i < model.images.length; ++i) {
      //console.log('model.images:', i, model.images[i])

      const uri = model.images[i].uri
      model.images[i].uri = blobs[model.images[i].uri]
        ? blobs[model.images[i].uri]
        : URL.createObjectURL(await onDownloadResource('texture', model.images[i].uri))

      for (const texture_name of ['Color', 'Alpha', 'AO', 'Depth', 'UniqueID', 'Root', 'Shade', 'Scalp']) {
        if (uri.includes(texture_name) && !uri.includes('ScalpShade')) {
          //             this.textures[texture_name] = uri
          this.textures[texture_name] = textureLoader.load(model.images[i].uri)
          this.textures[texture_name].flipY = false
          this.textures[texture_name].encoding = THREE.sRGBEncoding
        }
      }

      //      for (const texture_name of ['ScalpShadeAO', 'ScalpShadeAlpha']) {
      //        if (uri.includes(texture_name)) {
      //             this.textures[texture_name] = uri
      //          this.textures[texture_name] = textureLoader.load(model.images[i].uri)
      //          this.textures[texture_name].flipY = false
      //          this.textures[texture_name].encoding = THREE.sRGBEncoding
      //        }
      //      }
    }
    //console.log('model:', model)
    //console.log('this.textures:', this.textures)
    //console.log('this.textures.Scalp:', this.textures.Scalp)

    const url = URL.createObjectURL(new Blob([JSON.stringify(model, null, 2)], { type: 'text/plain' }))
    //console.log('url:', url)

    var gltfLoader = new GLTFLoader()
    const gltf = await gltfLoader.loadAsync(url)

    const nodes = await gltf.parser.getDependencies('node')
    const materials = await gltf.parser.getDependencies('material')

    this.clearModel()

    this.nodes = {}
    nodes.forEach((item) => {
      this.nodes[item.name] = item
    })

    this.materials = {}
    materials.forEach((material) => {
      //console.log('material:', material)

      material.onBeforeCompile = (shader) => {
        //console.log("material.onBeforeCompile()")
        //console.log("  material:", material)

        shader.vertexShader = shader.vertexShader.replace('#include <uv_vertex>', '#include <uv_vertex>\nvUv=uv;')

        shader.fragmentShader = shader.fragmentShader
          .replace(
            '#include <clipping_planes_pars_fragment>',
            '#include <clipping_planes_pars_fragment>\n' + haircut_fragment1,
          )
          .replace(
            'vec4 diffuseColor = vec4( diffuse, opacity );',
            'vec4 diffuseColor = vec4( diffuse, opacity );\n' + haircut_fragment2,
          )
          .replace('#include <map_fragment>', '//#include <map_fragment>')

        this.uniforms.uAO = { value: this.textures.AO }
        this.uniforms.uAlpha = { value: this.textures.Alpha }
        this.uniforms.uColor = { value: this.textures.Color }
        this.uniforms.uDepth = { value: this.textures.Depth }
        this.uniforms.uRoot = { value: this.textures.Root }
        this.uniforms.uShade = { value: this.textures.Shade }
        this.uniforms.uUniqueID = { value: this.textures.UniqueID }

        this.uniforms.uRecoloring.value = this.color !== null ? true : false

        shader.uniforms = { ...shader.uniforms, ...this.uniforms }

        //console.log('shader:', shader)
        //console.log("this:", this)
      }

      this.materials[material.name] = material
    })

    this.subtype = haircut.subtype
    this.name = haircut.preset
    this.color = null

    this.busy = false

    console.log('prepareHaircut() finished')
    //console.log('  this:', this)
  }

  /**
   * Haircut jsx
   *
   * param ref          - react.js useRef()
   */

  jsxHaircut = (ref, storeAvatarBody, params) => {
    //console.log('jsxHaircut()')
    //console.log('  name:', this.name)

    if (!this.nodes || !this.name || !this.nodes[this.name] || !this.nodes[this.name].geometry) return null

    this.ref = ref

    storeAvatarBody.setHaircutedHeadTexture(false)

    //return (<group {...params} dispose={null} ref={this.ref}></group>)

    return (
      <group {...params} dispose={null} ref={this.ref}>
        <primitive object={this.nodes.Hips} />
        <skinnedMesh
          name={this.name}
          geometry={this.nodes[this.name].geometry}
          material={this.materials[this.name]}
          skeleton={this.nodes[this.name].skeleton}
        />
      </group>
    )
  }

  /**
   * Recoloring Haircut to specified color
   *
   * colors - specified colors (target, roots)
   * params - params for proceeding (AOImpact, DepthImpact, IDsImpact)
   */

  recoloringHaircut = (colors, params) => {
    //console.log('recoloringHaircut')
    //console.log('  colors:', colors)
    //console.log('  params:', params)

    this.color = colors.target
    this.roots = colors.roots

    if (this.color === null) {
      // no recoloring
      this.uniforms.uRecoloring.value = false
      return
    }

    this.uniforms.uRecoloring.value = true

    this.uniforms.uTargetColor.value.x = ('0x' + this.color[1] + this.color[2]) / 255
    this.uniforms.uTargetColor.value.y = ('0x' + this.color[3] + this.color[4]) / 255
    this.uniforms.uTargetColor.value.z = ('0x' + this.color[5] + this.color[6]) / 255

    this.uniforms.uRootsColor.value.x = ('0x' + this.roots[1] + this.roots[2]) / 255
    this.uniforms.uRootsColor.value.y = ('0x' + this.roots[3] + this.roots[4]) / 255
    this.uniforms.uRootsColor.value.z = ('0x' + this.roots[5] + this.roots[6]) / 255

    this.uniforms.uAOImpact.value = params.AOImpact
    this.uniforms.uDepthImpact.value = params.DepthImpact
    this.uniforms.uIDsImpact.value = params.IDsImpact

    //console.log('this.uniforms:', this.uniforms)
  }
}

const storeHaircut = new Haircut()

export { storeHaircut }
