import React, { FC, useContext, useEffect, useState } from 'react'
import { useMedia } from 'react-use'

export type Theme = 'light' | 'dark'
export interface LayoutContextResult {
  displayBG: boolean
  setDisplayBG: (arg: boolean) => void
  selectedAvatars: { id: string; img: string }[]
  setSelectedAvatars: (arg: { id: string; img: string }[]) => void
  isOpenModal: boolean
  setIsOpenModal: (arg: boolean) => void
  mediaQueries: MediaQueries
  theme: Theme
}
export interface MediaQueries {
  isTablet: boolean
}

export const LayoutContext = React.createContext<LayoutContextResult>({} as LayoutContextResult)

export const useLayoutContext = () => useContext(LayoutContext)
export interface LayoutContextProps {
  children: React.ReactNode
}

export const LayoutProvider: FC<LayoutContextProps> = ({ children }) => {
  const isTablet = useMedia('(min-width: 768px)')
  const isLight = useMedia('(prefers-color-scheme: light)')

  const [displayBG, setDisplayBG] = useState<boolean>(false)
  const [selectedAvatars, setSelectedAvatars] = useState<{ id: string; img: string }[]>([])
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [mediaQueries, setMediaQueries] = useState<MediaQueries>({ isTablet })
  const [theme, setTheme] = useState<Theme>(isLight ? 'light' : 'dark')

  useEffect(() => {
    setMediaQueries({ isTablet })
  }, [isTablet])

  useEffect(() => {
    setTheme(isLight ? 'light' : 'dark')
  }, [isLight])

  return (
    <LayoutContext.Provider
      value={{
        displayBG,
        setDisplayBG,
        selectedAvatars,
        setSelectedAvatars,
        isOpenModal,
        setIsOpenModal,
        mediaQueries,
        theme,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
