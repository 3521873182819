/* eslint-disable prettier/prettier */
import React, { Suspense, useEffect, useRef, useState, useCallback } from 'react'

import * as THREE from 'three'
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls, PerspectiveCamera, Environment, AdaptiveDpr, Preload, Stats } from '@react-three/drei'
import anime from 'animejs'

import { AvatarViewerComponent } from './AvatarViewer'
import { storeAvatarBody } from './models/AvatarBody'
import { storeOutfits } from './models/Outfits'

export const defaultParams = {
  canvas: {
    maxPR: 2,
    minDPR: 0.5,
    adaptiveDPR: true,
    adaptiveFrameRate: true,
  },

  camera: {
    fov: 27,
    x: 0.0,
    y: 2.0,
    z: 4.5,
    a: 0.9,
    minZoom: 0.5,
  },

  light: {
    ambientIntensity: 0.1,
    directionalIntensity: 0.6,
  },
}

export const AvatarViewer = React.forwardRef((props, ref) => {
  //console.log('*************** AvatarViewer()')
  //console.log('  performance.memory.usedJSHeapSize, MB:', performance.memory.usedJSHeapSize / 1048576)

  //console.log('  avatar:', props.avatar)
  //console.log('  haircut:', props.haircut)
  //console.log("  outfits:", props.outfits)
  //console.log("  glasses:", props.glasses)
  //console.log('  animation:', props.animation)

  React.useImperativeHandle(
    ref,
    () => {
      return {
        changePosition: (position) => {
          //console.log('changePosition()', position)
          setCamPos(position)
        },

        changeBlendshapes: (blendshapes) => {
          //console.log('changeBlendshapes()', blendshapes)
          storeAvatarBody.changeBlendshapes(blendshapes)
          storeOutfits.changeBlendshapes(blendshapes)
        },
      }
    },
    [],
  )

  const camera = useRef()
  const controls = useRef()
  const renderer = React.useRef()

  const [cameraPosition] = useState(
    new THREE.Vector3(props.params.camera.x, props.params.camera.y, props.params.camera.z),
  )
  const [targetPosition] = useState(new THREE.Vector3(0, props.params.camera.a, 0))

  const [camPos, setCamPos] = useState('')

  useEffect(() => {
    navigate(camPos)
  }, [camPos])

  const navigate = (pos) => {
    //console.log('navigate(), pos:', pos)

    anime
      .timeline({
        easing: 'easeInOutQuart',
        duration: 2000,
        change: () => {
          controls.current?.update()
        },
      })
      .add({ targets: camera.current?.position, z: pos.z, y: pos.y, x: pos.x }, 0)
      .add({ targets: controls.current?.target, x: 0, y: pos.a, z: 0 }, 0)
      .add({ targets: controls.current, maxPolarAngle: pos.polar }, 0)
    //      .add({ targets: controls.current, minPolarAngle: pos.polar }, 0)
  }

  const Effects = () => {
    //console.log('Effects()')

    const regress = useThree((state) => state.performance.regress)

    useEffect(() => {
      controls.current?.addEventListener('change', regress)
    }, [])

    return null
  }

  const Renderer = () => {
    const { gl, scene, size } = useThree()

    useEffect(() => {
      if (camera?.current) {
        renderer.current = { gl, scene, camera: camera.current }

        //const canvas = document.getElementById('AvatarViewerCanvas')
        //          console.log('  CANVAS WIDTH:', canvas.clientWidth)
        //          console.log('  CANVAS HEIGHT:', canvas.clientHeight)

        props.setReady('camera', true)
      }
    }, [camera])

    return null
  }

  //          frameloop={props.params.canvas.adaptiveFrameRate ? 'demand' : 'always'}

  return (
    <Suspense fallback={null}>
      {process.env.USE_PREPAREDAVATAR === '1' ? <Stats showPanel={2} className='stats' width='100' /> : null}
      <Canvas
        id='AvatarViewerCanvas'
        dpr={[0.5, props.params.canvas.maxPR]}
        shadows
        performance={{
          min: props.params.canvas.minDPR,
          debounce: 200,
        }}
        frameloop={'always'}
        gl={{ alpha: true, antialias: true }}
      >
        <Environment path='/hdri/' files='empty_warehouse_01_1k.hdr' />

        <PerspectiveCamera fov={props.params.camera.fov} makeDefault ref={camera} position={cameraPosition}>
          <directionalLight
            intensity={props.params.light.directionalIntensity}
            position={new THREE.Vector3(10, 0, 5)}
          />
        </PerspectiveCamera>

        <ambientLight intensity={props.params.light.ambientIntensity} />

        <OrbitControls
          target={targetPosition}
          makeDefault
          minPolarAngle={0}
          maxPolarAngle={Math.PI / 1.75}
          minDistance={props.params.minZoom}
          maxDistance={20}
          // @ts-ignore dunno why not work
          ref={controls}
        />

        <AvatarViewerComponent
          avatar={props.avatar}
          haircut={props.haircut}
          glasses={props.glasses}
          outfits={props.outfits}
          animation={props.animation}
          params={props.params}
          onDownloadResource={props.onDownloadResource}
          setReady={props.setReady}
        />

        <Preload all />
        {props.params.canvas.adaptiveDPR && <AdaptiveDpr pixelated />}

        <Effects />
        <Renderer />
      </Canvas>
    </Suspense>
  )
})
