export const haircut_fragment2 = `
  if (uRecoloring) {
    vec4 AO       = texture2D(uAO,       vUv);
    vec4 Alpha    = texture2D(uAlpha,    vUv);
    vec4 Color    = texture2D(uColor,    vUv);
    vec4 Depth    = texture2D(uDepth,    vUv);
    vec4 Root     = texture2D(uRoot,     vUv);
    vec4 Shade    = texture2D(uShade,    vUv);
    vec4 UniqueID = texture2D(uUniqueID, vUv);

    vec4 targetColor = uTargetColor;
    vec4 rootsColor = uRootsColor;

#ifdef COLORSPACE_GAMMA
    AO       = gammaToLinear(AO);
    Depth    = gammaToLinear(Depth);
    Root     = gammaToLinear(Root);
    Shade    = gammaToLinear(Shade);
    UniqueID = gammaToLinear(UniqueID);

    targetColor = gammaToLinear(targetColor);
    rootsColor = gammaToLinear(rootsColor);
#endif

    vec4 col;
    col.rgb = Shade.rgb * targetColor.rgb;

    col = blendColorDodge(col, Shade);

    vec4 blendedAO = blendOverlay(col, AO);
    col = mix(col, blendedAO, uAOImpact);

    vec4 blendedDepth = blendSoftLight(col, Depth);
    col = mix(col, blendedDepth, uDepthImpact);

    vec4 blendedIDs = blendSoftLight(col, UniqueID);
    col = mix(col, blendedIDs, uIDsImpact);

    col = mix(col, rootsColor, Root.r);

#ifdef COLORSPACE_GAMMA
    col = linearToGamma(col);
#endif

    col.a = Alpha.r;

    diffuseColor = col;
  } else {
    diffuseColor = texture2D(uColor, vUv);
  }
`
