import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'astroturf/react'
import { useQuery, useQueryClient } from 'react-query'
import { useRQ, getSetting, createQR, request, getQRFile } from '@/services/adapters/req'
import { Container } from '@/components/wrapper'
import { Button, Loader } from '@/components/ui'
import { PhotoHandler } from './components'
import { storeGeneratorPipeline, storeAuth, storePollingQR } from '@/services/store'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'

import { Setting } from '../_helper'

export const Photo = observer(() => {
  const { nextSection, submitPreSettings, setStartStatePhoto, setPreSettings } = storeGeneratorPipeline
  const { isAuthorization } = storeAuth
  const { codeQR, uploadFile, cancelPolling, startPollingScan } = storePollingQR

  const queryClient = useQueryClient()
  const {
    theme,
    mediaQueries: { isTablet },
  } = useLayoutContext()

  const {
    data: {
      sections: {
        photo: { withoutNoPhoto },
      },
      texts: {
        sections: { photo: PAGE },
      },
    },
  } = useRQ<Setting>(getSetting)

  const [statusQR, setStatusQR] = useState<null | 'request' | 'pending' | 'done'>(null)
  const {
    isFetching: isLoadingQR,
    data: dataQR,
    error,
  } = useQuery(createQR.endpoint, async () => await request(createQR.endpoint, createQR), {
    enabled: isAuthorization && statusQR === 'request',
  })

  const [preview, setPreview] = useState<string>()
  const [isLoadedQR, setIsLoadedQR] = useState<boolean>(false)
  const [description, setDescription] = useState(isTablet ? PAGE.descr.desktop : PAGE.descr.mobile)
  const [visibleBlock, setVisibleBlock] = useState<'start' | 'scanQR' | 'displayPreview'>('start')

  const resetSection = useCallback(() => {
    setStatusQR(null)
    setPreview(undefined)
    cancelPolling()
    setIsLoadedQR(false)
    setTimeout(() => {
      queryClient.invalidateQueries(createQR.endpoint)
    }, 0)
  }, [])

  useEffect(() => {
    const start = statusQR === null && !preview && 'start'
    const fileFromDisk = statusQR === null && preview && 'fileFromDisk'
    const scanQR = statusQR !== null && !uploadFile && 'scanQR'
    const waitFile = statusQR === 'done' && storePollingQR.isWaitFile && 'waitFile'
    const descrKey = waitFile || scanQR || fileFromDisk || start

    const displayPreview = (statusQR === null || (statusQR && uploadFile)) && !!preview && 'displayPreview'

    let descr = ''
    switch (descrKey) {
      case start:
        descr = isTablet ? PAGE.descr.desktop : PAGE.descr.mobile
        break
      case scanQR:
        descr = PAGE.descr.scanQR
        break
      case waitFile:
        descr = PAGE.descr.after_scan_qr
        break
      case fileFromDisk:
        descr = PAGE.descr.readyToDownload
        break
    }

    setVisibleBlock(start || scanQR || displayPreview || 'start')
    setDescription(descr)
    setStartStatePhoto(start ? null : resetSection)
  }, [isTablet, statusQR, uploadFile, preview, storePollingQR.isWaitFile])

  useEffect(() => {
    if (dataQR && dataQR.code !== codeQR) {
      startPollingScan(dataQR.code)
    }
  }, [dataQR, codeQR])

  useEffect(() => {
    if (uploadFile) {
      resetSection()
      setPreSettings('photo', uploadFile)
      submitPreSettings()
    }
  }, [uploadFile])

  useEffect(() => {
    if (statusQR === 'request' && isLoadingQR) {
      setStatusQR('pending')
    }
    if (statusQR === 'pending' && !isLoadingQR) {
      setStatusQR('done')
    }
  }, [isLoadingQR, statusQR])

  useEffect(() => {
    if (dataQR && !isLoadingQR && statusQR) {
      getQRFile.endpoint = dataQR.upload_page_qr
      const fetchData = async () => {
        const imageObjectURL = await request(getQRFile.endpoint, getQRFile)
        setPreview(imageObjectURL)
        setIsLoadedQR(true)
      }
      fetchData()
    }
  }, [isLoadingQR, dataQR, statusQR])

  const handleFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setPreSettings('photo', e.target.files[0])
      const imageBlob = new Blob([e.target.files[0]], { type: e.target.files[0].type })
      const urlBlob = URL.createObjectURL(imageBlob)
      setPreview(urlBlob)
    }
  }, [])

  return (
    <Container variant='center'>
      <PhotoHandler
        title={PAGE.title}
        descr={description}
        img={preview}
        isBorder={theme === 'light' && isLoadedQR && (storePollingQR.isWaitQR || storePollingQR.isWaitFile)}
        error={error}
      >
        {visibleBlock === 'start' && (
          <>
            {isTablet ? (
              <Action wSize='full' onClick={() => setStatusQR('request')}>
                {PAGE.actions.new_photo}
              </Action>
            ) : (
              <ActionUpload>
                <Button as='p' wSize='full'>
                  {PAGE.actions.new_selfie}
                </Button>
                <Field type='file' accept='image/*' capture='user' onChange={handleFile} />
              </ActionUpload>
            )}
            <ActionUpload>
              <Button as='p' wSize='full' variant='surface'>
                {PAGE.actions.existing_photo}
              </Button>
              <Field type='file' accept='image/*' onChange={handleFile} />
            </ActionUpload>
            {!withoutNoPhoto && (
              <Action wSize='full' variant='surface' onClick={nextSection}>
                {PAGE.actions.without_your_photo}
              </Action>
            )}
          </>
        )}
        {visibleBlock === 'scanQR' && (
          <Action wSize='full' variant='surface' onClick={isLoadedQR ? resetSection : undefined}>
            {isLoadedQR ? PAGE.actions.cancel_qr_mode : <Loader variant='content' spinner='dots' />}
          </Action>
        )}
        {visibleBlock === 'displayPreview' && (
          <>
            <Action
              wSize='full'
              onClick={() => {
                resetSection()
                submitPreSettings()
              }}
            >
              {PAGE.actions.accept_and_continue}
            </Action>
            <Action wSize='full' variant='surface' onClick={resetSection}>
              {PAGE.actions.again}
            </Action>
          </>
        )}
      </PhotoHandler>
    </Container>
  )
})

const Action = styled(Button)`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

const ActionUpload = styled.label`
  display: block;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

const Field = styled.input`
  position: absolute;
  opacity: 0;
  left: -99999999px;
`
