import React from 'react'
import styled from 'astroturf/react'
import { Container } from '@/components/wrapper'
import { Row, Col } from 'react-grid-system'
import { AvatarPreview } from '@/components/ui'
import { TitleSection } from '@/views/components/common'
import img from '@/assets/demo/avatar-gallery.png'
import { storeGeneratorPipeline } from '@/services/store'

import { useRQ, getSetting } from '@/services/adapters/req'
import { Setting } from '../_helper'

export const NoPhoto = () => {
  const { submitPreSettings } = storeGeneratorPipeline
  const {
    data: {
      texts: {
        sections: { no_photo: PAGE },
      },
    },
  } = useRQ<Setting>(getSetting)

  return (
    <Container variant='center'>
      <TitleSection title={PAGE.title} descr={PAGE.descr} />
      <Avatars>
        <ColCard xs={6} sm={4}>
          <Avatar>
            <AvatarPreview
              handleClick={() => submitPreSettings('photo', btoa(img))}
              img={img}
              width={120}
              height={120}
            />
          </Avatar>
        </ColCard>
        <ColCard xs={6} sm={4}>
          <Avatar>
            <AvatarPreview
              handleClick={() => submitPreSettings('photo', btoa(img))}
              img={img}
              width={120}
              height={120}
            />
          </Avatar>
        </ColCard>
        <ColCard xs={6} sm={4}>
          <Avatar>
            <AvatarPreview
              handleClick={() => submitPreSettings('photo', btoa(img))}
              img={img}
              width={120}
              height={120}
            />
          </Avatar>
        </ColCard>
        <ColCard xs={6} sm={4}>
          <Avatar>
            <AvatarPreview
              handleClick={() => submitPreSettings('photo', btoa(img))}
              img={img}
              width={120}
              height={120}
            />
          </Avatar>
        </ColCard>
        <ColCard xs={6} sm={4}>
          <Avatar>
            <AvatarPreview
              handleClick={() => submitPreSettings('photo', btoa(img))}
              img={img}
              width={120}
              height={120}
            />
          </Avatar>
        </ColCard>
        <ColCard xs={6} sm={4}>
          <Avatar>
            <AvatarPreview
              handleClick={() => submitPreSettings('photo', btoa(img))}
              img={img}
              width={120}
              height={120}
            />
          </Avatar>
        </ColCard>
      </Avatars>
    </Container>
  )
}

const Avatars = styled(Row)`
  margin-top: 16px;
  max-width: 408px;
  margin-left: -8px !important;
  margin-right: -8px !important;
`

const ColCard = styled(Col)`
  margin-top: 16px;
  padding-left: 8px !important;
  padding-right: 8px !important;
`

const Avatar = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`
