export const avatarHead_fragment2_noscalp = `
  vec4 head    = texture2D(uHead,    vUv);
  vec4 haircut = texture2D(uHaircut, vUv);
//  vec4 scalp   = texture2D(uScalp,   vUv);

  if (uUseSkinRecoloring)
    head.rgb = clamp(head.rgb * uR, 0., 1.);

  if (uUseGeneratedHaircut) {
    if (uUseGeneratedHaircutRecoloring)
      haircut.rgb = mix(haircut.rgb + uTintCoeff*uColorTint, haircut.rgb, uDarkeningCoeff);

    diffuseColor = vec4( mix(head.rgb, haircut.rgb, haircut.a), 1.);
  } else {

//    float scalpAlpha = scalp.a;

//    if (uUseScalpRecoloring) {
//      scalp.rgb *= uScalpColor;
//      scalp.rgb = 1. - (1. - scalp.rgb) * (1. - uScalpColor*0.1);
//    }

//    diffuseColor = vec4(0., 0., 1., head.a);
//    diffuseColor = head;
//    diffuseColor = haircut;
//    diffuseColor = scalp;

    diffuseColor = head;
//    diffuseColor = vec4( mix(head.rgb, scalp.rgb, scalpAlpha), head.a);
  }
`
/*
fixed4 screen(fixed4 base, fixed4 blend)
            {
                return 1 - (1 - base) * (1 - blend);
            }

            sampler2D _MainTex;
            sampler2D _ScalpTex;
            float _IsRecoloring;
            fixed4 _TargetColor;

            fixed4 frag(v2f i) : SV_Target
            {
#if UNITY_COLORSPACE_GAMMA
                _TargetColor = gammaToLinear(_TargetColor);
#endif

                fixed4 headCol = tex2DInLinear(_MainTex, i.uv);
                fixed4 scalpCol = tex2DInLinear(_ScalpTex, i.uv);
                float scalpAlpha = scalpCol.a;

                if (_IsRecoloring)
                {
                    scalpCol *= _TargetColor;
                    scalpCol = screen(scalpCol, _TargetColor * 0.1);
                }
                    
                headCol.rgb = lerp(headCol, scalpCol, scalpAlpha).rgb;

#if UNITY_COLORSPACE_GAMMA
                headCol = linearToGamma(headCol);
#endif

                return headCol;
            }
*/
