import React, { useState, FC } from 'react'
import styled from 'astroturf/react'
import { useRQ, getSetting } from '@/services/adapters/req'
import { useLayoutContext } from '@/views/components/core/LayoutProvider'
import { SVGIcon, ApiKeysUnion } from '@/helpers/types'
import { Setting, MENU_FULL } from '@/views/page/generator/_helper'
import { observer } from 'mobx-react-lite'
import { storeEditor } from '@/services/store'
import { cameraPositions } from '../cameraPositions'

export type Components = 'Color' | 'Face' | 'Body' | 'Hairs' | 'Glasses' | 'Shirt'

export interface Acc {
  apiKey: ApiKeysUnion
  icon: SVGIcon
  component: FC
}

export const Menu = observer(() => {
  const { mediaQueries, theme } = useLayoutContext()
  const {
    data: {
      sections: { editor },
    },
  } = useRQ<Setting>(getSetting)

  const { isOutfitReady, isCamReady } = storeEditor
  const { haircut } = storeEditor

  //console.log("MENU_FULL:", MENU_FULL)

  editor.glasses = process.env.USE_GLASSES === '1' ? true : false
  //  console.log("editor:", editor)

  const [menuFiltered] = useState(() =>
    MENU_FULL.reduce((acc: Acc[], obj) => {
      if (editor[obj.apiKey]) {
        acc.push({
          apiKey: obj.apiKey,
          icon: obj.icon[theme],
          component: obj.component,
        })
      }
      return acc
    }, []),
  )
  const [Component, setComponent] = useState<FC | undefined>()
  const variant = mediaQueries.isTablet ? 'not-mobile' : 'mobile'

  //console.log("menuFiltered:", menuFiltered)

  const proceedClick = (cmpnt) => {
    //console.log("proceedClick()")
    //console.log("  cmpnt:", cmpnt)
    //console.log("  Component:", Component)

    setComponent((prev: FC | undefined) => (prev?.displayName === cmpnt.displayName ? undefined : cmpnt))

    if (!mediaQueries.isTablet) {
      storeEditor.viewer.changePosition(
        cmpnt.displayName === Component?.displayName
          ? cameraPositions('BODY', mediaQueries.isTablet)
          : cameraPositions(cmpnt.displayName, mediaQueries.isTablet),
      )
    }
  }

  return (
    <Core variant={variant}>
      <MenuBox variant={variant}>
        {menuFiltered.map(({ icon: Icon, component }) => (
          <MenuAction
            key={component.displayName}
            disabled={component.displayName === 'EditorHairColor' && haircut.preset === 'Bald' ? true : false}
            current={component.displayName === Component?.displayName}
            onClick={isCamReady && isOutfitReady ? () => proceedClick(component) : undefined}
          >
            <Icon />
          </MenuAction>
        ))}
      </MenuBox>
      {Component && <Component></Component>}
    </Core>
  )
})

export interface Variant {
  variant: 'not-mobile' | 'mobile'
}

const Core = styled.div<Variant>`
  &.variant-mobile {
    display: flex;
    flex-direction: column-reverse;
  }

  &.variant-not-mobile {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
  }
`

const MenuBox = styled.div<Variant>`
  background-color: var(--color-bg-box);
  &.variant-mobile {
    padding: 12px 34px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2px;
  }

  &.variant-not-mobile {
    margin-left: 20px;
    margin-right: 20px;
    border-radius: var(--size-radius-large);
    width: 72px;
    padding: 12px;
  }
`

export interface MenuActionProps {
  current: boolean
}

const MenuAction = styled.button<MenuActionProps>`
  @import '../../../../../../assets/styles/mixins.scss';
  @include anim(background-color);
  width: 48px;
  height: 48px;
  background-color: var(--color-bg-box);
  border-radius: var(--size-radius-small);

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &.current,
  &:hover {
    background-color: var(--color-bg-main);
  }

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`
