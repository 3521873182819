import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'astroturf/react'
import { Container } from '@/components/wrapper'
import { Button } from '@/components/ui'
import { TitleSection } from '@/views/components/common'
import { storeGeneratorPipeline } from '@/services/store'

import { useRQ, getSetting } from '@/services/adapters/req'
import { Setting } from '../_helper'

export const Gender = observer(() => {
  const { setAndNext } = storeGeneratorPipeline
  const {
    data: {
      texts: {
        sections: { gender: PAGE },
      },
    },
  } = useRQ<Setting>(getSetting)

  return (
    <Container variant='center'>
      <TitleSection title={PAGE.title} descr={PAGE.descr} />
      <Form>
        <Action
          variant='surface'
          wSize='full'
          onClick={() => {
            setAndNext('gender', 'male')
          }}
        >
          {PAGE.actions.male}
        </Action>
        <Action
          variant='surface'
          wSize='full'
          onClick={() => {
            setAndNext('gender', 'female')
          }}
        >
          {PAGE.actions.female}
        </Action>
        <Action
          variant='surface'
          wSize='full'
          onClick={() => {
            setAndNext('gender', 'female')
          }}
        >
          {PAGE.actions.without_specify}
        </Action>
      </Form>
    </Container>
  )
})

const Form = styled.div`
  margin-top: 32px;
  max-width: 360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const Action = styled(Button)`
  text-align: left;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`
